// Here you can add other styles
body {
	font-family: 'Golos Text', 'Roboto', sans-serif;
}

a {
	text-decoration: none;
}

.btn {
	font-family: 'Formular', sans-serif;
}

.my-button {

	border: none;
	background: none;
	

}

.sidebar-nav .nav-link.active {
	background: $warning;
}

.text-small {
	font-size: 0.8em;
}

img {
	max-width: 100%;
}

video {
	max-width: 100%;
	min-width: 100%;
}

.video-block {
	min-height: 150px;
}

.img-block {
	max-height: 200px;

	& img {
		max-width: 100%;
		max-height: 100%;
		object-fit: cover;	
	}
}

.img-block__remove-button {
	position: absolute;
	top: 0;
	right: 0;
	border: none;
	background: none;
	color: $danger;
}

.no-resize {
	resize: none;
}

.devider {
	width: 100%;
	height: 1px;
	background-color: $gray-300;
	margin: 20px 0;
}

.p-relative {
	position: relative;
}

.pointer {
	cursor: pointer;
}

.absolute-spinner {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba($color: #ffffff, $alpha: 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
}

.select-input {
	position: absolute;
	width: 100%;
	max-height: 400px;
	overflow-y: auto;
	top: 70px;
	left: 0;
	gap: 1em;

}

.card-hover-primary {
	&:hover {
		transition: .3s;
		border-color: $primary;
		color: $primary;
	}
}

.border-none {
	border: none !important;
}

.text-overflow {

	max-width: 100%;
	overflow: hidden;
	text-wrap: nowrap;
	text-overflow: ellipsis;

}

.disabled {

	background-color: var(--cui-secondary-bg);
	opacity: 1;

}

.background-black {

	background: black;

}

.popover-button {

	color: $gray-400;
	border: 1px solid $gray-500;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 14px;
	height: 14px;
	font-size: .7rem;
	font-weight: bold;

}

.emoji {
	position: absolute;
	top: 33px;
    right: 0px;
}

.emoji-button {
	position: absolute;
	top: 0;
	right: 0;
}

.emoji-picker {
	top: 40px;
	z-index: 999;
	& > :last-child {
		display: none;
	}
}

.upload-widget {

	position: absolute;
	width: 300px;
	right: 0;

}

.upload-widget__body {

	overflow: auto;
	max-height: 500px;

}

.flex-0 {
	flex: 0;
}

.flex-1 {
	flex: 1;
}
.custom-modal {

    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    // background: rgba(0, 0, 0, 0.5);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;

	&_close {
		opacity: 0;
		visibility: hidden;
	}

	&_open {
		opacity: 1;
		visibility: visible;
	}

	&__backdrop {
		position: absolute;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.5);
		transition: opacity 0.3s ease;

		&_close {
			opacity: 0;
		}
	}

	&__container {
		position: relative;
		background: white;
		border-radius: 8px;
		box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
		transform: scale(0.9);
		transition: transform 0.3s ease, opacity 0.3s ease;

		&_close {

			transform: scale(0.9);
			opacity: 0;

		}

		&_open {
			transform: scale(1);
			opacity: 1;
		}

	}

}

.drop-uploader {

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	flex-basis: 100%;
	width: 100%;
	min-height: 150px;
	height: 100%;
	border: 1px dashed var(--cui-border-color-translucent);
	border-radius: 8px;
	transition: .3s;
	padding: 5px;

	&_on-attachment {

		border-color: transparent;
		justify-content: flex-start;
		flex-direction: row;

	}

	&_active {

		border-color: $primary;

	}

	&__attachment-add {

		align-self: stretch;
		border: 1px dashed var(--cui-border-color-translucent);
		border-radius: 4px;
		display: flex;
		align-items: center;
		padding: 0 5px;
		transition: .3s;
		cursor: pointer;

		&:hover {
			border-color: $primary;
		}

	}

	&__button {

		border: none;
		background: none;
		
		transition: .3s;

		text-decoration: underline;

		&:hover {

			color: $primary;

		}

	}

}